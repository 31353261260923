import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';

const Index: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Fit Society</title>
        <meta
          name='description'
          content='Browse trainers, physical therapists, nutrition, apparel, and more near you without creating an account or
              hassling with payment info. It&rsquo;s free and always will be.'
        />
      </Head>
      <main>
        <img src='/android-chrome-192x192.png' alt='Fit society logo' />
        <br />
        <p>
          This website is still under construction, however the <Link href='/shop.html'>FITSOCIETY.COM shop</Link> is
          open.
        </p>
        <Link href='/shop.html'>
          <a className='shop-link'>Visit shop</a>
        </Link>
      </main>
      <style jsx>{`
        main {
          height: 100%;
          text-align: center;
          font-size: 1.125rem;
        }

        a.shop-link {
          display: block;
          margin: 16px auto;
          width: max-content;
          background: #e5e7eb;
          padding: 8px 16px;
          color: black;
          text-decoration: none;
          border-radius: 2px;
          font-size: 1rem;
          user-select: none;
          font-weight: 500;
        }

        a.shop-link:hover,
        a.shop-link:active {
          background: #d2d6dc;
        }
      `}</style>
    </div>
  );
};
export default Index;
